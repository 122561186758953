<app-hub-modal [title]="'Criar Usuário'">
    <div class="w-100 eu-display_flex eu-column" style="gap: 2rem; padding: 1rem">
        <form [formGroup]="form" class="eu-display_flex eu-column" style="gap: 2rem">
            <div class="eu-display_flex eu-align-items_center" style="gap: 1rem">
                <app-form-input
                    label="Nome"
                    formControlName="user_name"
                    [isRequired]="true"
                    [control]="form.get('user_name')"
                    [standAloneForm]="true"
                ></app-form-input>
                <app-form-input
                    label="Sobrenome"
                    formControlName="user_lastname"
                    [isRequired]="true"
                    [control]="form.get('user_lastname')"
                    [standAloneForm]="true"
                ></app-form-input>
                <app-form-input
                    label="CPF"
                    formControlName="document_number"
                    [isRequired]="true"
                    [control]="form.get('document_number')"
                    [fieldConfigs]="documentInputFieldConfigs"
                    [standAloneForm]="true"
                ></app-form-input>
                <app-form-input
                    label="E-mail"
                    formControlName="user_email"
                    [isRequired]="true"
                    [control]="form.get('user_email')"
                    [standAloneForm]="true"
                ></app-form-input>
                <app-form-input
                    label="Telefone"
                    formControlName="user_phone"
                    [isRequired]="true"
                    [control]="form.get('user_phone')"
                    [fieldConfigs]="phoneInputFieldConfigs"
                    [standAloneForm]="true"
                ></app-form-input>
            </div>
            <div class="eu-display_flex eu-align-items_center" style="gap: 1rem">
                <app-form-simple-options-input
                    label="Tipo de Acesso"
                    formControlName="user_type_id"
                    [isRequired]="true"
                    [control]="form.get('user_type_id')"
                    [data]="input.accountUserTypes"
                >
                </app-form-simple-options-input>
                @if (isBizDev()) {
                    <app-form-simple-options-input
                        label="Canal"
                        formControlName="channel"
                        [isRequired]="true"
                        [control]="form.get('channel')"
                        [data]="bizDevChannelOptions"
                    >
                    </app-form-simple-options-input>
                }

                <div class="" *ngIf="shouldShowPartnerLiveSelect()">
                    <label class="emd-label" for="">*Parceiro</label>
                    <select-live-search
                        attributeNameOfContentObjectOnListToOutput="name"
                        [list]="hubPartnersList()"
                        [inputPlaceholder]="'Selecione o parceiro'"
                        [selectedContent]="selectedPartner()?.name"
                        (getSelectedContent)="getSelectedHubPartner($event)"
                    >
                    </select-live-search>
                </div>

                @if (shouldShowBusinessUnitySelect()) {
                    <app-form-simple-options-input
                        label="Unidade de Negócio"
                        formControlName="sales_partner_id"
                        [isRequired]="true"
                        [control]="form.get('sales_partner_id')"
                        [data]="getBusinessUnities()"
                    >
                    </app-form-simple-options-input>
                }
            </div>
        </form>

        <div class="w-100 eu-display_flex eu-justify_end">
            <button class="emd-btn create-button" [disabled]="isCreatingUser || form.invalid" (click)="handleCreateNewUser()">
                <span *ngIf="!isCreatingUser" class="emd-text">Criar</span>
                <app-dot-loading [isLoading]="isCreatingUser" [color]="'var(--color-subtle)'"></app-dot-loading>
            </button>
        </div>
    </div>
</app-hub-modal>

import { Component, computed, input, Input, OnInit, output } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import moment from 'moment/moment'
import { ToastrService } from 'ngx-toastr'
import { ManageLoggedUser } from '../../../../authentication/services/manage-logged-user.service'
import { ConfirmModal } from '../../../../core/components/confirm-modal/confirm-modal.component'
import { ErrorListArrayMessageParser } from '../../../../core/services/error-list-array-message-parser.service'
import { HistoryModal } from '../../../../shared/components/history-modal/history-modal.component'
import { AccountUsersService } from '../../services/account-users.service'
import { AccountUser } from '../../types'
import { Option } from '../create-account-user-modal/types'

interface IUserType {
    name: string | null
    id: string | null
    type_id: string | null
    isInternal: boolean
}

interface IEdition {
    isEditing: boolean
    accountUserInEdition: string | undefined
    channel: string | undefined
    userType: {
        selectedUserType: {
            id: string | undefined
            name: string | undefined
        }
    }
}

@Component({
    selector: 'app-account-user-list',
    templateUrl: './account-user-list.component.html',
    styleUrls: ['./account-user-list.component.scss'],
})
export class AccountUserListComponent implements OnInit {
    @Input() accountUsers: AccountUser[] = []
    @Input() amountOfAccountUsers = 0
    @Input() isFetchingData = false
    @Input() openedViaBusinessManagement = false

    accountUserTypes = input<IUserType[]>([])
    internalUserTypes = computed(() => this.accountUserTypes().filter(t => t.isInternal))
    internalUserTypeIds = computed(() => this.internalUserTypes().map(t => t.type_id))
    externalUserTypes = computed(() => this.accountUserTypes().filter(t => !t.isInternal))
    externalUserTypeIds = computed(() => this.externalUserTypes().map(t => t.type_id))

    emitUpdatedAccountUser = output()

    isInternalUser = false

    loading: {
        isUpdatingAccountUser: boolean
        updatingAccountUserId: string | null
    } = {
        isUpdatingAccountUser: false,
        updatingAccountUserId: null,
    }

    edition: IEdition = {
        isEditing: false,
        accountUserInEdition: undefined,
        channel: undefined,
        userType: {
            selectedUserType: {
                id: undefined,
                name: undefined,
            },
        },
    }

    bizDevChannelOptions: Option[] = [
        { id: 'hunting', name: 'Hunting' },
        { id: 'farming', name: 'Farming' },
        { id: 'selfservice', name: 'Autosserviço' },
        { id: 'digital', name: 'Digital' },
    ]

    constructor(
        private modalService: NgbModal,
        private loggedUserService: ManageLoggedUser,
        private toastr: ToastrService,
        private accountUsersService: AccountUsersService,
        private errorListMessageParser: ErrorListArrayMessageParser,
    ) {}

    async ngOnInit(): Promise<void> {
        this.isInternalUser = this.loggedUserService.getLoggedAccountUser().loggedUser.hub_partner_id === 'none'
    }

    public getStatusColor(accountUser: AccountUser) {
        const currentStatus = this.getAccountUserStatus(accountUser)

        return currentStatus === 'Ativo' ? '_color_primary' : '_color_danger'
    }

    public getAccountUserStatus(accountUser: AccountUser) {
        if (accountUser.pre_register_validated === undefined) {
            return 'Usuário antigo pendente de migração'
        }

        if (!accountUser.pre_register_validated) {
            return 'Aceite do convite pendente'
        }

        if (accountUser.role_type === 'user-owner-partner') {
            if (!accountUser.terms_and_conditions) {
                return 'Aceite dos termos pendente'
            }

            if (
                accountUser.terms_and_conditions.partner_hub_terms_acceptance &&
                accountUser.terms_and_conditions.partner_program_terms_acceptance
            ) {
                return 'Ativo'
            }

            return 'Aceite do convite pendente'
        }

        return 'Ativo'
    }

    private clearEdition() {
        this.edition.isEditing = false
        this.edition.accountUserInEdition = undefined
        this.edition.userType.selectedUserType = { id: undefined, name: undefined }
    }

    private clearLoading() {
        this.loading.updatingAccountUserId = null
        this.loading.isUpdatingAccountUser = false
    }

    public canReSendInvite(accountUser: AccountUser) {
        return accountUser.allow_send_new_pre_register_invitation
    }

    public canUpdateUserStatus(accountUser: AccountUser) {
        return !accountUser.pre_register_validated
    }

    private deactivateAccountUser(accountUser: AccountUser) {
        this.loading.isUpdatingAccountUser = true
        this.loading.updatingAccountUserId = accountUser.account_user_id

        this.accountUsersService.deactivateAccountUser(accountUser.account_user_id).subscribe(
            data => {
                if (data.success) {
                    this.toastr.success('Usuário desativado com sucesso')
                    this.emitUpdatedAccountUser.emit()
                } else {
                    if (data.errorList[0]) {
                        this.errorListMessageParser.showErrorListMessage(data.errorList)
                    }
                }
            },
            response => {
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.error?.errorList,
                    'Falha não mapeada ao tentar desativar o usuário',
                )
            },
            () => this.clearLoading(),
        )
    }

    public handleDeactivateAccountUser(accountUser: AccountUser) {
        if (this.loggedUserService.getLoggedAccountUser().loggedUser.account_user_email === accountUser.email) {
            this.toastr.warning('Não é possível desativar o próprio usuário')
            return
        }

        const openConfirmModal = this.modalService.open(ConfirmModal, {
            size: 'sm',
            centered: true,
        })
        openConfirmModal.componentInstance.confirmationTitle = 'Deseja mesmo desativar este usuário?'

        openConfirmModal.result.then(result => {
            if (result.confirmation) {
                this.deactivateAccountUser(accountUser)
            }
        })
    }

    public handleGetAccountUserHistory(accountUser: AccountUser) {
        this.loading.isUpdatingAccountUser = true
        this.loading.updatingAccountUserId = accountUser.account_user_id

        this.accountUsersService.getAccountUserHistory(accountUser.account_user_id).subscribe(
            data => {
                if (data.success) {
                    this.showAccountHistoryModal(data.history.events, data.history.amount_of_history_events)
                }
            },
            response => {
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.erro?.errorList,
                    'Falha não mapeada ao tentar obter o histórico do usuário',
                )
            },
            () => this.clearLoading(),
        )
    }

    private showAccountHistoryModal(translatedHistory: any, amountOfHistoryEvents: any) {
        const openHistoryModal = this.modalService.open(HistoryModal, {
            size: 'lg',
            centered: true,
        })
        openHistoryModal.componentInstance.translatedHistory = translatedHistory
        openHistoryModal.componentInstance.translatedAmountOfHistoryEvents = amountOfHistoryEvents
    }

    private updateAccountUserRole(accountUser: AccountUser) {
        this.loading.isUpdatingAccountUser = true
        this.loading.updatingAccountUserId = accountUser.account_user_id
        this.accountUsersService
            .updateAccountUserRole({
                account_user_id: accountUser.account_user_id,
                user_type_id: this.edition.userType.selectedUserType.id,
                channel: this.isEditingBizDev(accountUser) ? this.edition.channel : null,
            })
            .subscribe(
                data => {
                    if (data.success) {
                        this.toastr.success('O tipo do usuário foi alterado com sucesso')
                        this.emitUpdatedAccountUser.emit()
                    } else {
                        if (data.errorList[0]) {
                            this.errorListMessageParser.showErrorListMessage(data.errorList)
                        }
                        return
                    }
                },
                response => {
                    this.errorListMessageParser.showErrorListMessageOrDefault(
                        response?.error?.errorList,
                        'Falha não mapeada ao tentar alterar o tipo do usuário',
                    )
                },
                () => {
                    this.clearEdition()
                    this.clearLoading()
                },
            )
    }

    public handleConfirmUpdatingAccountUser(accountUser: AccountUser) {
        const openConfirmModal = this.modalService.open(ConfirmModal, {
            size: 'sm',
            centered: true,
        })
        openConfirmModal.componentInstance.confirmationTitle = 'Confirmar alterações?'

        openConfirmModal.result.then(({ confirmation }) => confirmation && this.updateAccountUserRole(accountUser))
    }

    public handleToggleEditAccountUser(accountUser: AccountUser) {
        this.edition.isEditing = !this.edition.isEditing

        if (!this.edition.isEditing) {
            this.edition.accountUserInEdition = undefined
            this.edition.channel = undefined
            this.edition.userType.selectedUserType = { id: undefined, name: undefined }
        } else {
            this.edition.channel = accountUser.channel
            this.edition.userType.selectedUserType = {
                id: accountUser.user_type_id,
                name: accountUser.user_type_alias,
            }

            this.edition.accountUserInEdition = accountUser.account_user_id
        }
    }

    private resendInvitation(accountUser: AccountUser) {
        this.loading.isUpdatingAccountUser = true
        this.loading.updatingAccountUserId = accountUser.account_user_id

        this.accountUsersService.sendPreRegisterInvitation(accountUser.account_user_id).subscribe(
            data => {
                if (data.success) {
                    accountUser.allow_send_new_pre_register_invitation = false
                    accountUser.last_pre_register_invitation_formatted_date = moment().format('DD/MM/YYYY HH:mm:ss')
                    accountUser.last_pre_register_invitation_date = moment().format('YYYY-MM-DD HH:mm:ss')

                    this.toastr.success('Convite reenviado com sucesso')
                }
            },
            response => {
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.error?.errorList,
                    'Falha não mapeada ao tentar enviar o novo convite',
                )
            },
            () => this.clearLoading(),
        )
    }

    public handleResendInvitation(accountUser: AccountUser) {
        if (!accountUser.allow_send_new_pre_register_invitation || !accountUser.account_user_id) {
            this.toastr.error('Não é permitido reenviar o convite deste usuário')
            return
        }

        const openConfirmModal = this.modalService.open(ConfirmModal, { size: 'sm' })
        openConfirmModal.componentInstance.confirmationTitle = 'Deseja mesmo reenviar o convite?'

        openConfirmModal.result.then(result => {
            if (result.confirmation) {
                this.resendInvitation(accountUser)
            }
        })
    }

    public handleUploadUserStatus(accountUser: AccountUser) {
        if (accountUser.pre_register_validated) {
            this.toastr.error('Não é possível atualizar este usuário')
            return
        }

        const userObj = {
            id: accountUser.account_user_id,
            email: accountUser.email,
        }

        this.accountUsersService.updateUserStatus(userObj).subscribe(
            data => {
                console.log(data)
                if (data.success) {
                    this.toastr.success('Usuário atualizado com sucesso')
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                } else {
                    if (data.errorList[0]) {
                        this.errorListMessageParser.showErrorListMessage(data.errorList)
                    }
                }
            },
            response => {
                console.log(response)
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.error?.errorList,
                    'Falha não mapeada ao tentar atualizar o usuário',
                )
            },
        )
    }

    public isLoading(accountUser: AccountUser) {
        return this.loading.isUpdatingAccountUser && this.loading.updatingAccountUserId === accountUser.account_user_id
    }

    public isEditing(accountUser: AccountUser) {
        return this.edition.isEditing && this.edition.accountUserInEdition === accountUser.account_user_id
    }

    public isEditingBizDev(accountUser: AccountUser) {
        return (
            this.edition.isEditing &&
            this.edition.accountUserInEdition === accountUser.account_user_id &&
            this.edition.userType.selectedUserType.id === this.internalUserTypes().find(u => u.id === 'user-bizdev')?.type_id
        )
    }

    public getChannel(user: AccountUser) {
        return this.bizDevChannelOptions.find(o => o.id === user.channel)?.name ?? '-'
    }
}

import { Component, computed, inject, OnDestroy, OnInit, signal } from '@angular/core'
import { FormBuilder, UntypedFormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ErrorListArrayMessageParser } from '../../../../core/services/error-list-array-message-parser.service'
import { PartnersDataService } from '../../../../core/services/partners-data.service'
import { ComercialPartner } from '../../../../core/types/partners/partner-types'
import { FormValidatorHelper } from '../../../../shared/helpers/form-validator.helper'
import { AccountUsersService } from '../../services/account-users.service'
import { SelectedHubPartner } from './create-account-user-modal-component-types'
import { ModalInput, Option, UserTypeOption } from './types'
@Component({
    selector: 'app-create-account-user-modal',
    templateUrl: './create-account-user-modal.component.html',
    styleUrl: './create-account-user-modal.component.scss',
})
export class CreateAccountUserModalComponent implements OnInit, OnDestroy {
    readonly input = inject<ModalInput>(MAT_DIALOG_DATA)

    form!: UntypedFormGroup
    masks = {
        cpf: '000.000.000-00',
        cellPhone: '(00) 00000-0000',
    }

    isCreatingUser = false
    hubPartnersList = signal<ComercialPartner[]>([])
    selectedPartner = signal<SelectedHubPartner | undefined>(undefined)
    selectedUserType = signal<UserTypeOption | undefined>(undefined)
    shouldShowPartnerLiveSelect = computed(() => !this.input.partner && this.selectedUserType() && !this.selectedUserType()!.isInternal)
    shouldShowBusinessUnitySelect = computed(() => this.input.partner || this.selectedPartner())
    getBusinessUnities = computed(() => {
        if (this.input.partner) return this.input.partner?.business_unities ?? []
        return this.selectedPartner()?.business_unities ?? []
    })

    isBizDev = computed(() => this.selectedUserType()?.name === 'BizDev')
    bizDevChannelOptions: Option[] = [
        { id: 'hunting', name: 'Hunting' },
        { id: 'farming', name: 'Farming' },
        { id: 'selfservice', name: 'Autosserviço' },
        { id: 'digital', name: 'Digital' },
    ]

    onDestroyNotifier$ = new Subject()

    documentInputFieldConfigs = { placeholder: '___.___.___-__', mask: this.masks.cpf }
    phoneInputFieldConfigs = { placeholder: '(__) ____-____', ngMask: this.masks.cellPhone }

    constructor(
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private accountUsersService: AccountUsersService,
        private errorListMessageParser: ErrorListArrayMessageParser,
        private partnersDataService: PartnersDataService,
        private dialogRef: MatDialogRef<CreateAccountUserModalComponent>,
    ) {}

    ngOnInit(): void {
        this.createNewAccountUserForm()
        this.registerUserTypeValueChangeObserver()
        if (!this.input.partner) this.getHubPartnersList()
    }

    private createNewAccountUserForm() {
        this.form = this.formBuilder.group({
            user_name: [null, [FormValidatorHelper.required, FormValidatorHelper.maxLength(20)]],
            user_lastname: [null, [FormValidatorHelper.required, FormValidatorHelper.maxLength(20)]],
            document_number: [null, [FormValidatorHelper.required, FormValidatorHelper.cpfValidation]],
            user_email: [null, [FormValidatorHelper.required, FormValidatorHelper.email]],
            user_phone: [null, FormValidatorHelper.required],
            user_type_id: [null, FormValidatorHelper.required],
            sales_partner_id: [null, FormValidatorHelper.required],
            hub_partner_id: [null, FormValidatorHelper.required],
            channel: [undefined],
        })
    }

    private registerUserTypeValueChangeObserver() {
        this.form
            .get('user_type_id')
            ?.valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
            .subscribe({ next: userTypeId => userTypeId && this.defineFormRulesBySelectedUserType(userTypeId) })
    }

    private defineFormRulesBySelectedUserType(userTypeId: string) {
        const selectedUserType = this.input.accountUserTypes.find(type => type.id === userTypeId)
        this.selectedUserType.set(selectedUserType)

        this.selectedPartner.set(undefined)
        if (selectedUserType?.isInternal) {
            this.form.get('sales_partner_id')?.setValidators(null)
            this.form.get('sales_partner_id')?.setValue('Não associado')

            this.form.get('hub_partner_id')?.setValidators(null)
            this.form.get('hub_partner_id')?.setValue(null)
        } else {
            this.form.get('sales_partner_id')?.setValidators([FormValidatorHelper.required])
            this.form.get('sales_partner_id')?.setValue(null)

            this.form.get('hub_partner_id')?.setValidators([FormValidatorHelper.required])
            this.form.get('hub_partner_id')?.setValue(this.input.partner?.id ?? null)
        }

        if (this.isBizDev()) {
            this.form.get('channel')?.setValidators([FormValidatorHelper.required])
            this.form.get('channel')?.setValue(undefined)
        }
    }

    getHubPartnersList() {
        this.partnersDataService.getHubPartnersList().subscribe({
            next: ({ commercial_partners_list, errorList, success }) => {
                if (success) this.hubPartnersList.set(commercial_partners_list)
                else this.errorListMessageParser.showErrorListMessage(errorList)
            },
            error: response => {
                this.toastr.error('Não foi possível carregar lista de parceiros')
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.error?.errorList,
                    'Não foi possível carregar lista de parceiros',
                )
            },
        })
    }

    getSelectedHubPartner(selectedHubPartner: SelectedHubPartner) {
        this.selectedPartner.set(selectedHubPartner)
        this.form.get('hub_partner_id')?.setValue(selectedHubPartner.hub_partner_id)
        this.form.get('sales_partner_id')?.setValue(null)
    }

    private finishUserCreation() {
        this.toastr.success('Usuário convidado com sucesso')
        this.dialogRef.close(true)
    }

    removeMasks(value: string) {
        return value.replace(/[().-]/g, '').replace(/ /g, '')
    }

    sanitizeDocumentAndPhoneMasksBeforeSubmit() {
        this.form.get('document_number')?.setValue(this.removeMasks(this.form.getRawValue().document_number))

        this.form.get('user_phone')?.setValue(this.removeMasks(this.form.getRawValue().user_phone))
    }

    private createAccountUser() {
        this.sanitizeDocumentAndPhoneMasksBeforeSubmit()
        this.isCreatingUser = true
        this.accountUsersService.createAccountUserPreRegister(this.form.getRawValue()).subscribe(
            data => {
                if (data.success) {
                    if (data.userExistsOnAuthenticationUserService) {
                        this.toastr.error('Este usuário já possui acesso registrado no Partner Hub')
                    } else {
                        this.finishUserCreation()
                    }
                } else {
                    const { errorList } = data

                    this.errorListMessageParser.showErrorListMessageOrDefault(errorList, 'Falha não mapeada ao tentar criar o usuário')
                }
            },
            response => {
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.error?.errorList,
                    'Falha não mapeada ao tentar criar o usuário',
                )
            },
            () => (this.isCreatingUser = false),
        )
    }

    public handleCreateNewUser() {
        if (!this.form.valid) {
            this.toastr.error('Preencha todos os campos obritatórios para prosseguir com a criação')
            return
        }

        this.createAccountUser()
    }

    ngOnDestroy() {
        this.onDestroyNotifier$.next({})
    }
}

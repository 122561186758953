import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { SharedModule } from '../../shared/shared.module'
import { AccountUserListComponent } from './components/account-user-list/account-user-list.component'
import { CreateAccountUserModalComponent } from './components/create-account-user-modal/create-account-user-modal.component'
import { AccountUsersPageComponent } from './pages/account-users-page/account-users-page.component'

@NgModule({
    declarations: [AccountUserListComponent, AccountUsersPageComponent, CreateAccountUserModalComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild([{ path: 'list', component: AccountUsersPageComponent }]),
    ],
    exports: [AccountUsersPageComponent],
})
export class AccountUsersModule {}

<div class="eu-display_flex eu-column" style="gap: 0.625rem">
    <div class="emd-table-responsive">
        <div class="eu-display_flex eu-align-items_center" style="gap: 0.25rem">
            <span class="emd-text _color_primary">Total de Usuários:</span>
            <span class="emd-text _color_subtle">{{ amountOfAccountUsers }}</span>
        </div>
        <table class="emd-table hub-table">
            <thead>
                <th>Nome Completo</th>
                <th>Email</th>
                <th>Tipo</th>
                <th *ngIf="!openedViaBusinessManagement && isInternalUser">Canal</th>
                <th *ngIf="!openedViaBusinessManagement && isInternalUser">Parceiro</th>
                <th>Unidade de Negócio</th>
                <th>Criado em</th>
                <th>Último convite</th>
                <th>Status do acesso</th>
                <th>Ações</th>
            </thead>
            <tbody *ngIf="!isFetchingData && accountUsers.length > 0">
                <tr *ngFor="let accountUser of accountUsers; let accountUserIndex = index">
                    <td data-label="Nome Completo">
                        {{ accountUser.last_name ? accountUser.name + ' ' + accountUser.last_name : accountUser.name }}
                    </td>
                    <td data-label="Email">
                        {{ accountUser.email }}
                    </td>
                    <td data-label="Tipo">
                        <span *ngIf="!isEditing(accountUser)">{{ accountUser.user_type_alias }}</span>
                        <div *ngIf="isEditing(accountUser)" class="emd-select w-100">
                            <select [(ngModel)]="edition.userType.selectedUserType.id">
                                @for (
                                    type of internalUserTypeIds().includes(accountUser.user_type_id)
                                        ? internalUserTypes()
                                        : externalUserTypes();
                                    track $index
                                ) {
                                    <option [value]="type.type_id">
                                        <span class="emd-text _color_subtle"> {{ type.name }}</span>
                                    </option>
                                }
                            </select>
                            <div class="emd-select__arrow"></div>
                        </div>
                    </td>
                    <td data-label="Canal" *ngIf="!openedViaBusinessManagement && isInternalUser">
                        @if (isEditingBizDev(accountUser)) {
                            <div class="emd-select w-100">
                                <select name="Channel" [(ngModel)]="edition.channel">
                                    @if (!edition.channel) {
                                        <option [value]="undefined" disabled selected hidden>
                                            <span class="emd-text _color_subtle">Selecione</span>
                                        </option>
                                    }

                                    @for (channel of bizDevChannelOptions; track $index) {
                                        <option [value]="channel.id">
                                            {{ channel.name }}
                                        </option>
                                    }
                                </select>
                                <div class="emd-select__arrow"></div>
                            </div>
                        } @else {
                            {{ this.getChannel(accountUser) }}
                        }
                    </td>
                    <td data-label="Parceiro" *ngIf="!openedViaBusinessManagement && isInternalUser">
                        {{ accountUser?.partner_name ? accountUser.partner_name : '-' }}
                    </td>
                    <td data-label="Unidade de Negócio">
                        {{ accountUser?.business_unity_name ? accountUser.business_unity_name : '-' }}
                    </td>
                    <td data-label="Criado em">
                        {{ accountUser.created_at_formatted_date }}
                    </td>
                    <td data-label="Último convite">
                        <span
                            [ngClass]="{
                                'emd-text _color_danger': accountUser.allow_send_new_pre_register_invitation,
                            }"
                        >
                            {{ accountUser.last_pre_register_invitation_formatted_date }}
                        </span>
                    </td>
                    <td data-label="Status do acesso">
                        <span class="emd-text _size_section" [ngClass]="getStatusColor(accountUser)">
                            {{ getAccountUserStatus(accountUser) }}
                        </span>
                    </td>
                    <td data-label="Ações">
                        <div
                            class="eu-display_flex eu-align-items_center eu-cursor_pointer"
                            style="gap: 0.25rem"
                            *ngIf="!isEditing(accountUser) && !isLoading(accountUser)"
                        >
                            <i
                                class="fa fa-pencil-square-o fa-lg eu-cursor_pointer edit-button"
                                aria-hidden="true"
                                title="Editar usuário"
                                (click)="handleToggleEditAccountUser(accountUser)"
                            ></i>
                            <i
                                class="fa fa-trash fa-lg eu-cursor_pointer delete-button"
                                aria-hidden="true"
                                title="Desativar usuário"
                                (click)="handleDeactivateAccountUser(accountUser)"
                            ></i>
                            <i
                                class="fa fa-history fa-lg eu-cursor_pointer edit-button"
                                aria-hidden="true"
                                title="Histórico"
                                (click)="handleGetAccountUserHistory(accountUser)"
                            ></i>
                            <i
                                *ngIf="canReSendInvite(accountUser)"
                                class="fa fa-paper-plane fa-lg eu-cursor_pointer edit-button"
                                aria-hidden="true"
                                title="Reenvio de convite"
                                (click)="handleResendInvitation(accountUser)"
                            ></i>
                            <i
                                *ngIf="canUpdateUserStatus(accountUser)"
                                class="fa fa-refresh fa-lg eu-cursor_pointer edit-button"
                                aria-hidden="true"
                                title="Atualizar usuário"
                                (click)="handleUploadUserStatus(accountUser)"
                            ></i>
                        </div>
                        <div
                            class="eu-display_flex eu-align-items_center"
                            *ngIf="isEditing(accountUser) && !isLoading(accountUser)"
                            style="gap: 1rem"
                        >
                            <i
                                class="fa fa-check _color_success eu-cursor_pointer primary-button"
                                title="Confirmar alteração"
                                (click)="handleConfirmUpdatingAccountUser(accountUser)"
                                aria-hidden="true"
                            ></i>
                            <i
                                class="fa fa-times _color_danger eu-cursor_pointer danger-button"
                                title="Cancelar alteração"
                                (click)="handleToggleEditAccountUser(accountUser)"
                                aria-hidden="true"
                            ></i>
                        </div>
                        <div *ngIf="isLoading(accountUser)" class="eu-display_flex eu-justify_center">
                            <div class="spinner-border loading-indicator"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div
        *ngIf="isFetchingData || amountOfAccountUsers === 0"
        class="emd-container w-100 eu-display_flex eu-justify_center fallback-container"
    >
        <div *ngIf="isFetchingData" class="spinner-border" style="color: var(--color-primary)"></div>
        <span *ngIf="!isFetchingData && amountOfAccountUsers === 0" class="emd-text _size_section">Nenhum usuário encontrado</span>
    </div>
</div>
